import React from 'react';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-card">
        <div className="footer-content">
          {/* Footer Top */}
          <div className="footer-top">
            {/* Left Section */}
            <div className="top-left-section">
              <div className="left-logo-section">
                <img
                  src="https://hubxpert.com/hubfs/Hubxpert-logo-for-print%201.svg"
                  alt="HubXpert Logo"
                  className="footer-logo"
                />
              </div>
              <div className="logo-paragraph">
                <p className="paragraph2-desktop">Hubxpert, Asia’s fastest-growing HubSpot Platinum & Odoo Ready Partner. We specialise in CRM, ERP, web development, inbound marketing, and sales enablement.</p>
              </div>
            </div>
            {/* Right Section */}
            <div className="top-right-section">
              <div className="footer-column">
                <h5 className="h5-desktop">HubSpot Services</h5>
                <ul className="button-text3-desktop">
                  <li>
                    <a href="https://hubxpert.com/hubspot-api-integrations">CRM Setup & Integration</a>
                  </li>

                  <li>
                    <a href="https://hubxpert.com/hubspot-marketing-hub-implementation">Marketing Automation</a>
                  </li>


                  <li>
                    <a href="https://hubxpert.com/hubspot-sales-hub-implementation">Sales Hub Implementation</a>
                  </li>

                  <li>
                    <a href="https://hubxpert.com/hubspot-content-hub-implementation">CMS Development</a>
                  </li>

                  <li>
                    <a href="https://example.com/service1">Training & Consulting</a>
                  </li>
                </ul>
              </div>
              <div className="footer-column">
                <h5 className="h5-desktop">White Label Services</h5>
                <ul className="button-text3-desktop">
                  <li>
                    <a href="#">SEO</a>
                  </li>
                  <li>
                    <a href="https://hubxpert.com/">Web Development</a>
                  </li>
                  <li>
                    <a href="https://hubxpert.com/">PPC Management</a>
                  </li>
                  <li>
                    <a href="https://hubxpert.com/">Social Media Management</a>
                  </li>
                  <li>
                    <a href="https://hubxpert.com/">Content Creation</a>
                  </li>
                </ul>
              </div>
              <div className="footer-column">
                <h5 className="h5-desktop">Other Services</h5>
                <ul className="button-text3-desktop">
                  <li>
                    <a href="https://hubxpert.com/hubspot-white-label">HubSpot White Label</a>
                  </li>
                  <li>
                    <a href="https://hubxpert.com/cold-email-services">Cold Email Services</a>
                  </li>
                  <li>
                    <a href="https://hubxpert.com/">Custom Web Development</a>
                  </li>
                  <li>
                    <a href="https://hubxpert.com/ui-ux-design">UI/UX Design</a>
                  </li>
                  <li>
                    <a href="https://hubxpert.com/odoo-erp-implementation">Odoo ERP Implementation</a>
                  </li>
                </ul>
              </div>
              <div className="footer-column">
                <h5 className="h5-desktop">Recent Blogs</h5>
                <ul className="button-text3-desktop">
                  <li>
                    <a href="https://blog.hubxpert.com/?_gl=1*qf2r86*_ga*Mjk1MTM2NDExLjE3MzU3MjA4OTg.*_ga_V825ZP2MFB*MTc0MDM5NjE1MS4xMC4xLjE3NDAzOTc3OTEuMzIuMC4w*_gcl_au*ODgwNDA3MDYwLjE3MzU3MjA4OTc.">Marketing Strategies</a>
                  </li>
                  <li>
                    <a href="https://blog.hubxpert.com/?_gl=1*1w1f7f5*_gcl_au*ODgwNDA3MDYwLjE3MzU3MjA4OTc.*_ga*Mjk1MTM2NDExLjE3MzU3MjA4OTg.*_ga_V825ZP2MFB*MTc0MDM5NjE1MS4xMC4xLjE3NDAzOTc5ODYuMTMuMC4w">Business Growth</a>
                  </li>
                  <li>
                    <a href="https://blog.hubxpert.com/?_gl=1*1x314te*_gcl_au*ODgwNDA3MDYwLjE3MzU3MjA4OTc.*_ga*Mjk1MTM2NDExLjE3MzU3MjA4OTg.*_ga_V825ZP2MFB*MTc0MDM5NjE1MS4xMC4xLjE3NDAzOTgwNjMuNTkuMC4w">Sales Efficiency</a>
                  </li>
                  <li>
                    <a href="https://blog.hubxpert.com/?_gl=1*1a9cuv2*_gcl_au*ODgwNDA3MDYwLjE3MzU3MjA4OTc.*_ga*Mjk1MTM2NDExLjE3MzU3MjA4OTg.*_ga_V825ZP2MFB*MTc0MDM5NjE1MS4xMC4xLjE3NDAzOTgwODMuMzkuMC4w">Digital Marketing</a>
                  </li>
                  <li>
                    <a href="https://blog.hubxpert.com/?_gl=1*1a9cuv2*_gcl_au*ODgwNDA3MDYwLjE3MzU3MjA4OTc.*_ga*Mjk1MTM2NDExLjE3MzU3MjA4OTg.*_ga_V825ZP2MFB*MTc0MDM5NjE1MS4xMC4xLjE3NDAzOTgwODMuMzkuMC4w">Revenue Growth</a>
                  </li>
                  <li>
                    <a href="https://blog.hubxpert.com/?_gl=1*1a9cuv2*_gcl_au*ODgwNDA3MDYwLjE3MzU3MjA4OTc.*_ga*Mjk1MTM2NDExLjE3MzU3MjA4OTg.*_ga_V825ZP2MFB*MTc0MDM5NjE1MS4xMC4xLjE3NDAzOTgwODMuMzkuMC4w">Marketing Potential</a>
                  </li>
                </ul>
              </div>
              <div className="footer-column">
                <h5 className="h5-desktop">Quick Links</h5>
                <ul className="button-text3-desktop">
                  <li>
                    <a href="https://hubxpert.com/hubspot-onboarding?_gl=1*1oi8v9j*_gcl_au*ODgwNDA3MDYwLjE3MzU3MjA4OTc.*_ga*Mjk1MTM2NDExLjE3MzU3MjA4OTg.*_ga_V825ZP2MFB*MTc0MDM5NjE1MS4xMC4xLjE3NDAzOTgxMTYuNi4wLjA.">HubSpot Onboarding</a>
                  </li>

                  <li>
                    <a href="https://hubxpert.com/hubspot-white-label">White Label</a>
                  </li>

                  <li>
                    <a href="https://hubxpert.com/work">Our Work</a>
                  </li>

                  
                  <li>
                    <a href="https://blog.hubxpert.com/?_gl=1*17quks7*_gcl_au*ODgwNDA3MDYwLjE3MzU3MjA4OTc.*_ga*Mjk1MTM2NDExLjE3MzU3MjA4OTg.*_ga_V825ZP2MFB*MTc0MDM5NjE1MS4xMC4xLjE3NDAzOTgzMDYuNjAuMC4w">Blogs</a>
                  </li>

                  <li>
                    <a href="https://hubxpert.com/about-us?_gl=1*11pwe5v*_gcl_au*ODgwNDA3MDYwLjE3MzU3MjA4OTc.*_ga*Mjk1MTM2NDExLjE3MzU3MjA4OTg.*_ga_V825ZP2MFB*MTc0MDM5NjE1MS4xMC4xLjE3NDAzOTgzNDUuMjEuMC4w">About Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Footer Middle */}
          <div className="footer-middle">
            {/* Office Locations */}
            <div className="office-locations">
              <div className="location">
                <img
                  src="https://hubxpert.com/hubfs/london-eye%20(3)%201.svg"
                  alt="United Kingdom"
                  className="location-icon"
                />
                <div className="location-details">
                  <h5 className="h5-desktop">United Kingdom</h5>
                  <p className="paragraph2-desktop">2nd Floor College House, 17 King Edwards Road, Ruislip, London, HA4 7AE, United Kingdom</p>
                </div>
              </div>
              <div className="location">
                <img
                  src="https://hubxpert.com/hubfs/national-martyrs-memorial-jatiya-sriti-shoudho-bangladesh.svg"
                  alt="Bangladesh"
                  className="location-icon"
                />
                <div className="location-details">
                  <h5 className="h5-desktop">Bangladesh</h5>
                  <p className="paragraph2-desktop">418 & 419, Apollo Road, Dhaka 1212</p>
                </div>
              </div>
            </div>

            {/* Our Partnerships */}
            <div className="partnerships">
              <h5 className="section-title h5-desktop">Our Partnerships</h5>
              <div className="partnership-badges">
                <img
                  src="https://hubxpert.com/hubfs/SVG_platinum-badge-color.svg"
                  alt="HubSpot Gold Partner Badge"
                  className="badge-image"
                />
                <img
                  src="https://hubxpert.com/hubfs/Group%2041.svg"
                  alt="Odoo Ready Partner Badge"
                  className="badge-image"
                />
              </div>
            </div>

            {/* Certifications and Ratings */}
            <div className="certifications-ratings">
              <span className="clutch_rating rating">
                <a href="https://clutch.co">
                  <img
                    src="https://hubxpert.com/hubfs/Group%20(3).svg"
                    alt="Clutch Rating"
                    className="clutch certification-icon"
                  />

                <img
                  src="https://hubxpert.com/hubfs/Frame%201171274942.svg"
                  alt="rating-star"
                  className="stars"
                />
                </a>
                
              </span>
              <span className="goodfirms_rating rating">
                <a href="https://g2.com">
                  <img
                    src="https://hubxpert.com/hubfs/Growd-logo%201.svg"
                    alt="Crowd Rating"
                    className="crowd certification-icon"
                  />
                  <img
                  src="https://hubxpert.com/hubfs/Frame%201171274942.svg"
                  alt="rating-star"
                  className="stars"
                />
                </a>
                
              </span>
              <span className="hubspot_rating rating">
                <a href="https://hubspot.com">
                  <img
                    src="https://hubxpert.com/hubfs/hubspot-seeklogo%202.svg"
                    alt="HubSpot Rating"
                    className="hub-up certification-icon"
                  />
                  <img
                  src="https://hubxpert.com/hubfs/Frame%201171274942.svg"
                  alt="rating-star"
                  className="stars"
                />
                </a>
              </span>
              <span className="upwork_rating rating">
                <a href="https://upwork.com">
                  <img
                    src="https://hubxpert.com/hubfs/upwork%20(1)%201.svg"
                    alt="Upwork Rating"
                    className="hub-up certification-icon"
                  />
                  <img
                    src="https://hubxpert.com/hubfs/Frame%201171274942.svg"
                    alt="rating-star"
                    className="stars"
                  />
                </a>
              
              </span>
            </div>
          </div>

          {/* Footer Bottom */}
          <div className="footer-bottom">
            <div className="social-links">
              <a href="https://linkedin.com">
                <img
                  className="linkedin"
                  src="https://hubxpert.com/hubfs/010-linkedin.png"
                  alt="Linkedin"
                />
              </a>
              <a href="https://upwork.com">
                <img
                  className="upwork"
                  src="https://hubxpert.com/hubfs/upwork-roundedsquare-1%201.png"
                  alt="Upwork"
                />
              </a>
              <a href="https://facebook.com">
                <img
                  className="fb"
                  src="https://hubxpert.com/hubfs/001-facebook.png"
                  alt="Facebook"
                />
              </a>
            </div>
            <div className="footer-text">
              <p className="paragraph2-desktop">© 2025 Your Company</p>
              <div className="footer-links">
                <a className="paragraph2-desktop" href="https://example.com/terms">
                  Terms &amp; Conditions
                </a>
                <a className="paragraph2-desktop" href="https://example.com/privacy">
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CSS – do not change any of the CSS */}
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap');
          @import url('https://fonts.cdnfonts.com/css/helvetica-neue-5');
          
          .footer-container {
            font-size: 16px;
            padding: 0em 1.25em 1.25em 1.25em;
            background: var(--white-background1);
          }
          .footer-card {
            border-radius: 20px;
            background: var(--white-background2);
            padding: 2.5em 0em 1.25em 0em;
          }
          .button-text3-desktop {
            font-size: 1em;
          }
          .footer-content {
            max-width: 1440px;
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            padding: 1rem;
          }
          .footer-top {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 6.25em;
            padding: 1.25em 0;
            border-bottom: 1px solid #64bae2;
          }
          .top-left-section {
            width: 17%;
            display: flex;
            flex-direction: column;
          }
          .left-logo-section {
            display: flex;
            align-items: center;
          }
          .left-logo-section img {
            max-width: 14.5rem;
            position: relative;
            bottom: 1.4em;
          }
          .logo-paragraph {
            position: relative;
          }
          .logo-paragraph p {
            color: var(--black);
          }
          .top-right-section {
            display: flex;
            flex-wrap: nowrap;
            flex: 2;
            gap: 3em;
          }
          .footer-column {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .footer-column h5, .location-details h5{
            text-decoration: underline;
            color: #04587a;
            font-size: 1rem;
            font-weight: 500;
            font-family: "Poppins", serif;
            margin-bottom: 1.25em;
          }
          .footer-column ul {
            list-style: none;
            padding: 0;
            margin: 0;
          }
          .footer-column ul li {
            margin-bottom: 1.25em;
          }
          .footer-column ul li a {
            text-decoration: none;
            font-weight: 500;
            color: #001b34;
            transition: color 0.3s ease;
          }
          .footer-column ul li a:hover {
            color: #64bae2;
          }
          .footer-middle {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
            padding: 40px 0;
            border-bottom: 1px solid #64bae2;
            gap: 1.875em;
            height: 16rem;
          }

          .stars{
            width: 120px;
          }

          .h5-desktop{
                text-decoration: underline;
                color: #04587a;
                margin-bottom: 1.25em;
          }

          .office-locations {
            height: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 20px;
            border-right: 1px solid #64bae2;
            padding-right: 4.25em;
          }
          .location {
            display: flex;
            align-items: flex-start;
            gap: 20px;
          }
          .location-icon {
            width: 40px;
            height: 40px;
          }
          .location-details h5 {
            margin: 0;
            color: #04587a;
            text-decoration: underline;
          }
          .location-details p {
            margin: 10px 0 0;
            color: var(--black);
          }
          .partnerships {
            flex: 1;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          .section-title {
            text-decoration: underline;
            color: #04587a;
            font-size: 0.90rem;
            font-weight: 500;
            font-family: "Poppins", serif;
            margin-bottom: 1.25em;
          }
          .partnership-badges {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2.5em;
          }
          .badge-image {
            width: 100%;
            max-height: 116px;
          }
          .certifications-ratings {
            flex: 1;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            justify-items: center;
            border-left: 1px solid #64bae2;
            padding-left: 4.25em;
            height: 100%;
          }
          .certification-icon {
            width: 100%;
            max-width: 150px;
            height: 36px;
          }
          .footer-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            padding: 21px 0px 0px 0px;
          }
          .footer-bottom .social-links {
            display: flex;
            gap: 20px;
          }
          .footer-bottom .social-links a {
            color: #002b5c;
            text-decoration: none;
            transition: color 0.3s ease;
          }
          .footer-bottom .social-links a:hover {
          }
          .fb:hover {
            filter: brightness(0) saturate(100%) invert(87%) sepia(21%) saturate(5782%) hue-rotate(169deg) brightness(95%) contrast(85%);
            transition: color 0.3s ease;
          }
          .linkedin:hover {
            filter: brightness(0) saturate(100%) invert(87%) sepia(21%) saturate(5782%) hue-rotate(169deg) brightness(95%) contrast(85%);
            transition: color 0.3s ease;
          }
          .upwork:hover {
            content: url('https://example.com/upwork-hover.png');
          }
          .footer-bottom .footer-text {
            display: flex;
            align-items: center;
            text-align: center;
            gap: 80px;
          }
          .footer-bottom .footer-text p {
            color: var(--black);
          }
          .footer-bottom .footer-links {
            display: flex;
            gap: 40px;
          }
          .footer-bottom .footer-links a {
            color: var(--black);
            text-decoration: none;
            transition: color 0.3s ease;
          }
          .footer-bottom .footer-links a:hover {
            text-decoration: underline;
            color: #64bae2;
          }
          .rating {
            display: flex;
            flex=direction: column;
          }
          .crowd {
            max-width: 118px;
          }
          .hub-up {
            max-width: 134px;
          }
          .clutch {
            max-width: 101px;
          }
          @media (max-width: 1069px) {
            .footer-middle {
              height: 14rem;
              flex-wrap: nowrap;
            }
            .rating img {
              width: 90%;
            }
            .certifications-ratings {
              height: 87%;
            }
          }
          @media (max-width: 1000px) {
            .top-right-section {
              gap: 7em;
            }
          }
          @media (max-width: 900px) {
            .badge-image {
            }
            .left-logo-section img {
              max-width: 13.5em;
            }
            .logo-paragraph {
            }
          }
          @media (max-width: 768px) {
            .footer-content {
              padding: 1rem;
            }
            .footer-container {
              font-size: 16px;
            }
            .footer-top {
              display: flex;
              flex-direction: column;
              gap: 0px;
              padding-top: 0px;
            }
            .footer-card {
              padding-top: 0px;
            }
            .top-left-section {
              margin-top: 20px;
              gap: 0px;
              width: 100%;
              max-height: 9rem;
              margin-bottom: 20px;
            }
            .logo-paragraph {
              margin-top: 0px;
            }
            .top-right-section {
              display: flex;
              flex-direction: column !important;
              gap: 1rem;
            }
            .footer-column ul li {
              margin-bottom: 1.75rem;
            }
            .footer-column h5 {
              margin-bottom: 1.625em;
            }
            .footer-middle {
              display: flex;
              flex-direction: column;
              height: auto;
              gap: 1rem;
              padding: 1rem 0;
            }
            .office-locations {
              display: flex;
              flex-direction: column;
              border-right: none;
              border-bottom: 1px solid #64bae2;
              padding-bottom: 40px;
            }
            .partnership-badges {
              flex-wrap: wrap;
              width: 100%;
              justify-content: flex-start;
            }
            .location {
              display: flex;
              flex-direction: column;
            }
            .partnerships {
              padding-left: 0em;
              align-items: flex-start;
            }
            .certifications {
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .certifications-ratings {
              border-left: none;
              padding-left: 0em;
              border-top: 1px solid #64bae2;
              width: 100%;
              justify-items: start;
              padding-top: 1rem;
            }
            .badge-image {
              height: auto;
              width: auto;
            }
            .clutch_rating {
              display: flex;
              flex-direction: column;
            }
            .goodfirms_rating {
              display: flex;
              flex-direction: column;
            }
            .hubspot_rating {
              display: flex;
              flex-direction: column;
            }
            .upwork_rating {
              display: flex;
              flex-direction: column;
            }
            .footer-bottom {
              display: flex;
              flex-direction: column;
              gap: 1.5rem;
            }
            .footer-text {
              display: flex;
              flex-direction: column;
            }
            .footer-bottom .footer-text {
              gap: 1rem;
            }
            .footer-bottom .social-links {
              gap: 1.5rem;
            }
          }
          .footer-container {
            font-size: 16px;
          }
          @media (max-width: 1920px) {
            .footer-container {
              font-size: 14.4px;
            }
          }
          @media (max-width: 1728px) {
            .footer-container {
              font-size: 12px;
            }
          }
          @media (max-width: 1440px) {
            .footer-container {
              font-size: 10.7px;
            }
          }
          @media (max-width: 1280px) {
            .footer-container {
              font-size: 10px;
            }
          }
          @media (max-width: 1024px) {
            .footer-container {
              font-size: 9px;
            }
          }
          @media (max-width: 768px) {
            .footer-container {
              font-size: 16px;
            }
          }
          @media (max-width: 383px) {
            .top-left-section {
              max-height: 10rem;
            }
          }
        `}
      </style>
    </footer>
  );
};

export default Footer;
